var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "row mt-4" },
    [
      _c("div", { staticClass: "form-group w-100 mb-1 ml-2 mt-2" }, [
        !_vm.editMode
          ? _c("label", [_vm._v("Google Course Owner")])
          : _c("label", [_vm._v("Change Google Course Owner")]),
      ]),
      _vm.editMode
        ? [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group px-3" }, [
                _c("div", { staticClass: "input-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedSchoolStaffId,
                          expression: "selectedSchoolStaffId",
                        },
                      ],
                      staticClass: "custom-select form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedSchoolStaffId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }),
                      _vm._l(_vm.teachers, function (t) {
                        return _c(
                          "option",
                          {
                            key: `staff_${t.schoolStaffId}`,
                            domProps: { value: t.schoolStaffId },
                          },
                          [_vm._v(" " + _vm._s(t.googleEmail) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "input-group-append" },
                    [
                      _vm.saving
                        ? [_vm._m(0)]
                        : _vm.success
                        ? [_vm._m(1)]
                        : !_vm.saving
                        ? [
                            _vm.selectedSchoolStaffId
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-secondary kt-font-bold kt-font-primary",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.save()
                                      },
                                    },
                                  },
                                  [_vm._v(" Set ")]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "input-group-text",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        _vm.showEditor = false
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "la la-close" })]
                                ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]
        : [
            _c("div", { staticClass: "col-8 pl-3" }, [
              _c("div", { staticClass: "kt-widget4 pt-2 pb-2" }, [
                _vm.courseOwner
                  ? _c("div", { staticClass: "kt-widget4__item" }, [
                      _c(
                        "div",
                        { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                        [
                          _c(
                            "UserAvatar",
                            { attrs: { "avatar-user": _vm.courseOwner } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-badge kt-badge--lg kt-badge--success",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.courseOwner.lastName.substring(0, 1)
                                      ) +
                                      _vm._s(
                                        _vm.courseOwner.firstName.substring(
                                          0,
                                          1
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "kt-widget4__info" },
                        [
                          _vm.courseOwner.schoolEmail
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "kt-widget4__username",
                                  attrs: {
                                    to: {
                                      name: "TeacherInfo",
                                      params: {
                                        schoolEmail:
                                          _vm.courseOwner.schoolEmail,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.courseOwner.lastName) +
                                      ", " +
                                      _vm._s(_vm.courseOwner.firstName) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "a",
                            {
                              staticClass: "kt-widget4__username",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.unknownOwner) + " ")]
                          ),
                          _c(
                            "p",
                            { staticClass: "kt-widget4__text email-label" },
                            [_vm._v(" Course Owner ")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm.unknownOwner
                  ? _c("div", { staticClass: "kt-widget4__item" }, [
                      _c(
                        "div",
                        { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-badge kt-badge--lg kt-badge--warning",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.unknownOwner.substring(0, 2)) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "kt-widget4__info" }, [
                        _c(
                          "a",
                          {
                            staticClass: "kt-widget4__username",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.unknownOwner) + " ")]
                        ),
                        _c(
                          "p",
                          { staticClass: "kt-widget4__text email-label" },
                          [_vm._v(" Course Owner ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "col-4 pr-3" }, [
              _vm.canEdit && _vm.canEditCourse
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-clean btn-sm mt-2 pull-right",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.showEditor = true
                        },
                      },
                    },
                    [_c("i", { staticClass: "la la-user" }), _vm._v(" Edit ")]
                  )
                : _vm._e(),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticClass: "input-group-text d-block",
        staticStyle: { "min-width": "44px" },
      },
      [
        _c("i", {
          staticClass: "kt-spinner kt-spinner--sm kt-spinner--primary d-block",
          staticStyle: { "margin-top": "10px" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", { staticClass: "la la-check kt-font-success" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
<div class="mt-4">
    <div class="form-group w-100 mb-0">
        <label @click="refresh()">
            Google Teachers
        </label>
    </div>
    <div
        v-for="teacher in googleTeachers"
        :key="teacher.googleEmail"
        class="row w-100 py-2"
    >
        <div class="col-11">
            <div class="kt-widget4">
                <div class="kt-widget4__item">
                    <div class="kt-widget4__pic kt-widget4__pic--pic">
                        <UserAvatar :avatar-user="teacher">
                            <div class="kt-badge kt-badge--lg kt-badge--primary">
                                {{ teacher.lastName.substring(0,1) }}{{ teacher.firstName.substring(0,1) }}
                            </div>
                        </UserAvatar>
                    </div>
                    <div
                        :title="teacher.googleEmail"
                        class="kt-widget4__info"
                    >
                        <router-link
                            class="kt-widget4__username"
                            :to="{name: 'TeacherInfo', params: {schoolEmail: teacher.schoolEmail}}"
                        >
                            {{ teacher.lastName }}, {{ teacher.firstName }}
                        </router-link>
                        <p
                            class="kt-widget4__text"
                            v-html="$_utilMixins_format_email(teacher.googleEmail) "
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-1 pr-0">
            <a
                v-if="googleTeachers.length > 1 && canEditCourse"
                href="#"
                class="pull-right"
                @click.stop.prevent="removeTeacher(teacher)"
            >
                <i class="la la-close pt-4" />
            </a>
        </div>
    </div>

    <div
        v-if="!showAddTeacher && canEditCourse"
        class="w-100 my-4"
    >
        <a
            href="#"
            class="btn btn-clean btn-sm pull-right"
            @click.stop.prevent="showAddTeacher = true"
        >
            <i class="la la-user-plus" />
            Add Google Teacher
        </a>
    </div>
    <div
        v-if="showAddTeacher && canEditCourse"
        class="kt-section kt-section--first mb-2 w-100"
    >
        <div class="kt-section__body">
            <div class="form-group pb-3 pt-4">
                <label>Add New Google Teacher:</label>
                <div class="input-group">
                    <select
                        v-model="selectedSchoolStaffId"
                        class="custom-select form-control"
                    >
                        <option value="" />
                        <option
                            v-for="t in teachers"
                            :key="`gct_${t.schoolStaffId}`"
                            :value="t.schoolStaffId"
                        >
                            {{ t.lastName }}, {{ t.firstName }} - {{ t.googleEmail }}
                        </option>
                    </select>

                    <div
                        class="input-group-append"
                    >
                        <template v-if="saving">
                            <span
                                class="input-group-text d-block"
                                style="min-width: 44px;"
                            >
                                <i
                                    style="margin-top: 10px"
                                    class="kt-spinner kt-spinner--sm kt-spinner--primary d-block"
                                />
                            </span>
                        </template>
                        <template v-else-if="success">
                            <span class="input-group-text">
                                <i class="la la-check kt-font-success" />
                            </span>
                        </template>
                        <template v-else-if="!saving">
                            <button
                                v-if="selectedSchoolStaffId"
                                class="btn btn-secondary kt-font-bold kt-font-primary"
                                @click.stop.prevent="addGoogleTeacher()"
                            >
                                Add
                            </button>
                            <span
                                v-else
                                class="input-group-text"
                                @click.stop.prevent="showAddTeacher = false"
                            >
                                <i class="la la-close" />
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import UserAvatar from './UserAvatar.vue';
import userMixins from '../store/mixins/userMixins';
import utilMixins from '../store/mixins/utilMixins';
import courseMixins from '../store/mixins/courseMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import * as network from '../network';
import Types from '../store/Types';

export default Vue.extend({
    name: 'GoogleCourseTeachers',
    components: {
        UserAvatar,
    },
    mixins: [
        userMixins,
        utilMixins,
        googleCourseMixins,
        courseMixins,
    ],
    data() {
        return {
            key: 0,
            showAddTeacher: false,
            selectedSchoolStaffId: 0,
            saving: false,
            success: false,
        };
    },
    computed: {
        googleCourseTeachers() {
            return this.$store.state.database.googleCourseTeachers;
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        googleTeachers() {
            const { googleCourse } = this;
            if (!googleCourse) return [];
            return googleCourse.googleTeachers || [];
        },
        teachers() {
            const { teachers } = this.$store.state.database;
            return teachers.filter((t) => t.googleEmail);
        },
        editMode() {
            const { canEdit, showEditor } = this;
            return (canEdit && showEditor);
        },
        unknownProxy() {
            const { googleCourse } = this;
            const { googleCourseCourseSections } = this.$store.state.database;

            if (!googleCourse) return null;
            const { googleCourseId } = googleCourse;

            const credentials = googleCourseCourseSections
                .find((c) => c.googleCourseId == googleCourseId);

            if (!credentials) return null;
            return credentials.googleEmailAddress;
        },
        proxyCreds() {
            const { googleCourse } = this;
            const { googleCourseCourseSections, teachers } = this.$store.state.database;

            if (!googleCourse) return null;
            const { googleCourseId } = googleCourse;

            const credentials = googleCourseCourseSections
                .filter((c) => c.googleCourseId == googleCourseId)
                .map((c) => teachers.find((t) => t.googleEmail == c.googleEmailAddress) || null)
                .find((c) => c); // remove nulls

            if (!credentials) return null;
            return credentials;
        },
        canEdit() {
            const googleEmail = this.$_userMixins_googleEmail;
            const role = this.$_userMixins_userRole;
            if (role == 'School Admin') return true;
            if (!this.proxyCreds) return false;
            if (googleEmail == this.proxyCreds.googleEmail) return true;
            return false;
        },
        canEditCourse() {
            return this.course.canEditCourse || false;
        },
    },
    watch: {
        googleCourseTeachers() {
            this.key += 1;
        },
    },
    methods: {
        selectTeacher(teacher) {
            this.$store.commit('openQuickPanelForTeacher', teacher);
        },
        removeTeacher(teacher) {
            const emailAddress = teacher.googleEmail;

            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { googleCourseId } = this.googleCourse;
            const { schoolId, schoolTermId } = this.$_userMixins_school;

            const { showError, $store } = this;
            const params = {
                url: {
                    schoolTermId,
                    schoolId,
                    googleCourseId,
                },
                body: {
                    emailAddress,
                },
            };

            // Start Google Loader
            this.$store.commit(Types.mutations.CACHE_GOOGLE_COURSE_LOADING, this.googleCourse);
            network.google.removeTeacherFromCourse(params, (err) => {
                v.saving = false;
                if (err) return showError(err);
                $store.commit(Types.mutations.REMOVE_GOOGLE_COURSE_TEACHER, { googleCourseId, emailAddress });
                v.success = true;
                v.refresh(); // this will kill the loader on complete
            });
        },
        addGoogleTeacher() {
            const { selectedSchoolStaffId, teachers, googleCourse } = this;
            if (!selectedSchoolStaffId) return;

            if (this.saving) return;
            this.saving = true;
            this.success = false;

            const teacher = teachers.find((t) => t.schoolStaffId == selectedSchoolStaffId);
            if (!teacher) return;

            const v = this;

            const { googleEmail } = teacher;
            const { googleCourseId } = googleCourse;
            const { schoolId, schoolTermId } = this.$_userMixins_school;

            const { showError, $store } = this;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    googleCourseId,
                },
                body: {
                    emailAddress: googleEmail,
                },
            };

            // Start Google Loader
            this.$store.commit(Types.mutations.CACHE_GOOGLE_COURSE_LOADING, this.googleCourse);
            network.google.addTeacherToCourse(params, (err, res) => {
                v.saving = false;
                if (err) return showError(err);

                const [t] = res.googleTeachers;
                $store.commit(Types.mutations.UPSERT_GOOGLE_COURSE_TEACHER, {
                    googleCourseId,
                    googleTeacherId: t.googleTeacherId,
                    deleted: false,
                });

                v.success = true;
                v.refresh(); // this will kill the loader on complete
            });
        },
        refresh() {
            const v = this;
            setTimeout(() => {
                v.key += 1;
                v.selectedSchoolStaffId = 0;
                v.showAddTeacher = false;
                v.showEditor = false;
                v.success = false;
            }, 2000);

            v.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSE, (refreshErr) => {
                if (refreshErr) v.showError(refreshErr);
            });
        },
    },
});
</script>

<style scoped>
i.la-close {
    color: #93a2dd !important;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "form-group w-100 mx-2 mb-0" }, [
      _c("label", { staticClass: "mb-2" }, [_vm._v("Google Course")]),
      _c(
        "p",
        {
          staticClass:
            "form-control-static py-2 mb-0 kt-font-bold kt-font-lg kt-font-dark",
        },
        [
          _c(
            "span",
            {
              staticClass: "kt-badge kt-badge--inline kt-badge--bold mr-3 mb-1",
              class: {
                "kt-badge--danger":
                  _vm.googleCourse.googleCourseState == "DELETED",
                "kt-badge--warning":
                  _vm.googleCourse.googleCourseState == "ARCHIVED" ||
                  _vm.googleCourse.googleCourseState == "PROVISIONED",
                "kt-badge--success":
                  _vm.googleCourse.googleCourseState == "ACTIVE",
              },
            },
            [_vm._v(" " + _vm._s(_vm.googleCourse.googleCourseState) + " ")]
          ),
          _vm.googleCourse.alternateLink
            ? [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href: _vm.googleCourse.alternateLink,
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.googleCourse.googleCourseName) + " "
                    ),
                  ]
                ),
              ]
            : [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.googleCourse.googleCourseName) + " "),
                ]),
              ],
        ],
        2
      ),
    ]),
    _vm.badCourseLink && _vm.canEditCourse
      ? _c(
          "div",
          { staticClass: "alert alert-warning my-4", attrs: { role: "alert" } },
          [
            _vm._m(0),
            _c("div", { staticClass: "alert-text" }, [
              _vm._v(
                " This google course must be ACTIVE in order to be used in SyncGrades. Remove this link and choose another course, or make the course ACTIVE then refresh data. "
              ),
              _vm.isNotDeleted
                ? _c(
                    "div",
                    { staticClass: "w-100 d-flex justify-content-end" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.activateCourse.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "la la-edit" }),
                          _vm._v(" Activate Course "),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
    _vm.needsRefresh
      ? _c(
          "div",
          { staticClass: "alert alert-warning my-4", attrs: { role: "alert" } },
          [
            _vm._m(1),
            _c("div", { staticClass: "alert-text" }, [
              _vm._v(
                " This course is being provisioned in google. Wait a few moments and try to refresh google data again. Typically, google places courses in this locked state temporarily. "
              ),
            ]),
          ]
        )
      : _vm.notGoogleTeacher
      ? _c(
          "div",
          {
            staticClass: "alert alert-secondary my-4",
            attrs: { role: "alert" },
          },
          [
            _vm._m(2),
            _c("div", { staticClass: "alert-text" }, [
              _c("code", [_vm._v(_vm._s(_vm.googleEmail))]),
              _vm._v(
                " is not a google teacher for this course, so the above google link may be inaccessible. "
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "w-100 mt-2 mb-4" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-clean btn-clean-primary btn-sm",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.refresh.apply(null, arguments)
            },
          },
        },
        [
          _c("i", { staticClass: "la la-refresh" }),
          _vm._v(" Refresh Google Data "),
        ]
      ),
      _vm.role == "School Admin" || _vm.canEditCourse
        ? _c(
            "a",
            {
              staticClass: "btn btn-clean btn-clean-danger btn-sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.removeLink.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "la la-remove" }), _vm._v(" Remove Link ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-questions-circular-button" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
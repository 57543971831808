var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "kt-widget4 mb-3" },
        _vm._l(_vm.courseTeachers, function (teacher) {
          return _c(
            "div",
            { key: teacher.schoolEmail, staticClass: "kt-widget4__item" },
            [
              _c(
                "div",
                { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                [
                  _c("UserAvatar", { attrs: { "avatar-user": teacher } }, [
                    _c(
                      "div",
                      {
                        staticClass: "kt-badge kt-badge--lg kt-badge--success",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(teacher.lastName.substring(0, 1)) +
                            _vm._s(teacher.firstName.substring(0, 1)) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kt-widget4__info" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "kt-widget4__username",
                      attrs: {
                        to: {
                          name: "TeacherInfo",
                          params: { schoolEmail: teacher.schoolEmail },
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(teacher.lastName) +
                          ", " +
                          _vm._s(teacher.firstName) +
                          " "
                      ),
                    ]
                  ),
                  _c("p", {
                    staticClass: "kt-widget4__text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$_utilMixins_format_email(teacher.schoolEmail)
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm.courseTeachers.length > 0 && _vm.canEditCourse
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-icon-sm",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.removeTeacher(teacher)
                        },
                      },
                    },
                    [_c("i", { staticClass: "la la-close" })]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      _vm.canEditCourse
        ? [
            !_vm.showAddTeacher
              ? _c("div", { staticClass: "w-100" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-clean btn-sm mt-2 pull-right",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.showAddTeacher = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "la la-user-plus" }),
                      _vm._v(" Add New Teacher "),
                    ]
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "kt-section kt-section--first mb-2 w-100" },
                  [
                    _c("div", { staticClass: "kt-section__body" }, [
                      _c("div", { staticClass: "form-group pb-3 pt-4" }, [
                        _c("label", [_vm._v("Add New Teacher:")]),
                        _c("div", { staticClass: "input-group" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedSchoolStaffId,
                                  expression: "selectedSchoolStaffId",
                                },
                              ],
                              staticClass: "custom-select form-control",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedSchoolStaffId = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }),
                              _vm._l(_vm.teachers, function (t) {
                                return _c(
                                  "option",
                                  {
                                    key: `${_vm.extCourseSectionId}_${t.schoolStaffId}`,
                                    domProps: { value: t.schoolStaffId },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(t.lastName) +
                                        ", " +
                                        _vm._s(t.firstName) +
                                        " - " +
                                        _vm._s(t.schoolEmail) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "input-group-append" },
                            [
                              _vm.saving
                                ? [_vm._m(1)]
                                : _vm.success
                                ? [_vm._m(2)]
                                : !_vm.saving
                                ? [
                                    _vm.selectedSchoolStaffId
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary kt-font-bold kt-font-primary",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.save()
                                              },
                                            },
                                          },
                                          [_vm._v(" Add ")]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [
                                            _c("i", {
                                              staticClass: "la la-plus",
                                            }),
                                          ]
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group mb-3" }, [
      _c("label", [_vm._v(" Course Teachers ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticClass: "input-group-text d-block",
        staticStyle: { "min-width": "44px" },
      },
      [
        _c("i", {
          staticClass: "kt-spinner kt-spinner--sm kt-spinner--primary d-block",
          staticStyle: { "margin-top": "10px" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", { staticClass: "la la-check kt-font-success" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
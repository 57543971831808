<template>
<div class="row mt-4">
    <div class="form-group w-100 mb-1 ml-2 mt-2">
        <label v-if="!editMode">Google Course Owner</label>
        <label v-else>Change Google Course Owner</label>
    </div>
    <template v-if="editMode">
        <div class="col-12">
            <div class="form-group px-3">
                <div class="input-group">
                    <select
                        v-model="selectedSchoolStaffId"
                        class="custom-select form-control"
                    >
                        <option value="" />
                        <option
                            v-for="t in teachers"
                            :key="`staff_${t.schoolStaffId}`"
                            :value="t.schoolStaffId"
                        >
                            {{ t.googleEmail }}
                        </option>
                    </select>

                    <div class="input-group-append">
                        <template v-if="saving">
                            <span
                                class="input-group-text d-block"
                                style="min-width: 44px;"
                            >
                                <i
                                    style="margin-top: 10px"
                                    class="kt-spinner kt-spinner--sm kt-spinner--primary d-block"
                                />
                            </span>
                        </template>
                        <template v-else-if="success">
                            <span class="input-group-text">
                                <i class="la la-check kt-font-success" />
                            </span>
                        </template>
                        <template v-else-if="!saving">
                            <button
                                v-if="selectedSchoolStaffId"
                                class="btn btn-secondary kt-font-bold kt-font-primary"
                                @click.stop.prevent="save()"
                            >
                                Set
                            </button>
                            <span
                                v-else
                                class="input-group-text"
                                @click.stop.prevent="showEditor = false"
                            >
                                <i class="la la-close" />
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="col-8 pl-3">
            <div class="kt-widget4 pt-2 pb-2">
                <div
                    v-if="courseOwner"
                    class="kt-widget4__item"
                >
                    <div class="kt-widget4__pic kt-widget4__pic--pic">
                        <UserAvatar :avatar-user="courseOwner">
                            <div class="kt-badge kt-badge--lg kt-badge--success">
                                {{ courseOwner.lastName.substring(0,1) }}{{ courseOwner.firstName.substring(0,1) }}
                            </div>
                        </UserAvatar>
                    </div>
                    <div class="kt-widget4__info">
                        <router-link
                            v-if="courseOwner.schoolEmail"
                            class="kt-widget4__username"
                            :to="{name: 'TeacherInfo', params: {schoolEmail: courseOwner.schoolEmail}}"
                        >
                            {{ courseOwner.lastName }}, {{ courseOwner.firstName }}
                        </router-link>
                        <a
                            class="kt-widget4__username"
                            href="#"
                            @click.stop.prevent=""
                        >
                            {{ unknownOwner }}
                        </a>
                        <p class="kt-widget4__text email-label">
                            Course Owner
                        </p>
                    </div>
                </div>
                <div
                    v-else-if="unknownOwner"
                    class="kt-widget4__item"
                >
                    <div class="kt-widget4__pic kt-widget4__pic--pic">
                        <div class="kt-badge kt-badge--lg kt-badge--warning">
                            {{ unknownOwner.substring(0,2) }}
                        </div>
                    </div>
                    <div class="kt-widget4__info">
                        <a
                            class="kt-widget4__username"
                            href="#"
                            @click.stop.prevent=""
                        >
                            {{ unknownOwner }}
                        </a>
                        <p class="kt-widget4__text email-label">
                            Course Owner
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 pr-3">
            <a
                v-if="canEdit && canEditCourse"
                class="btn btn-clean btn-sm mt-2 pull-right"
                href="#"
                @click.stop.prevent="showEditor = true"
            >
                <i class="la la-user " />
                Edit
            </a>
        </div>
    </template>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import UserAvatar from './UserAvatar.vue';
import userMixins from '../store/mixins/userMixins';
import courseMixins from '../store/mixins/courseMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import * as network from '../network';
import Types from '../store/Types';

export default Vue.extend({
    name: 'GoogleCourseOwner',
    components: {
        UserAvatar,
    },
    mixins: [
        userMixins,
        googleCourseMixins,
        courseMixins,
    ],
    data() {
        return {
            saving: false,
            success: false,
            showEditor: false,
            selectedSchoolStaffId: 0,
        };
    },
    computed: {
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        teachers() {
            return this.googleCourse.googleTeachers;
        },
        editMode() {
            const { canEdit, showEditor } = this;
            return (canEdit && showEditor);
        },
        unknownOwner() {
            const { googleCourse } = this;
            if (!googleCourse) return null;
            return googleCourse.extOwnerId;
        },
        courseOwner() {
            const { googleCourse } = this;
            if (!googleCourse) return null;
            const { extOwnerId } = googleCourse;
            const { googleTeachers } = this.$store.state.database;
            const credentials = googleTeachers
                .find((c) => c.googleUserId == extOwnerId);

            if (!credentials) return null;
            return credentials;
        },
        canEdit() {
            const googleEmail = this.$_userMixins_googleEmail;
            const role = this.$_userMixins_userRole;
            if (role == 'School Admin') return true;
            if (!this.courseOwner) return false;
            if (googleEmail == this.courseOwner.googleEmail) return true;
            return false;
        },
        canEditCourse() {
            return this.course.canEditCourse || false;
        },
    },
    methods: {
        save() {
            const { selectedSchoolStaffId, teachers, googleCourse } = this;
            if (!selectedSchoolStaffId) return;

            if (this.saving) return;
            this.saving = true;

            const teacher = teachers.find((t) => t.schoolStaffId == selectedSchoolStaffId);
            if (!teacher) return;

            const v = this;

            const { googleEmail } = teacher;
            const { googleCourseId } = googleCourse;
            const { schoolId, schoolTermId } = this.$_userMixins_school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    googleCourseId,
                    googleEmail,
                },
            };
            const { showError, $store } = this;

            network.google.changeCourseOwner(params, (err, res) => {
                v.saving = false;
                if (err) return showError(err);

                const { extOwnerId } = res.googleCourse;
                $store.commit(Types.mutations.EDIT_GOOGLE_COURSE_OWNER, { googleCourseId, extOwnerId });
                v.success = true;
                setTimeout(() => {
                    v.selectedSchoolStaffId = 0;
                    v.showEditor = false;
                    v.success = false;
                }, 2000);
            });
        },
        selectTeacher(teacher) {
            this.$store.commit('openQuickPanelForTeacher', teacher);
        },
    },
});
</script>

<template>
<div
    v-if="showCourseCreationPortlet"
    class="kt-portlet"
>
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon pr-0">
                <SVGIcon
                    hex-color="#0f88ef"
                    class="mr-3"
                    name="googleClassroom"
                />
            </span>
            <h3 class="kt-portlet__head-title">
                <span>
                    Create Google Course
                </span>
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <a
                href="#"
                class="btn btn-icon"
                @click.stop.prevent="reset"
            >
                <i class="la la-close" />
            </a>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="form-group">
            <label>Course Title:</label>
            <input
                v-model="courseTitle"
                type="text"
                class="form-control"
                :placeholder="`${extCourseSectionId} ${course.name}`"
            >
        </div>
        <div class="form-group">
            <label class="d-block w-100">
                Google Teachers:
                <code class="pull-right">
                    {{ googleDomain }}
                </code>
            </label>
            <div
                v-if="googleTeachers.length > 0"
                class="kt-widget4 pt-4"
            >
                <div
                    v-for="googleTeacher in googleTeachers"
                    :key="`googleEmail_${googleTeacher.googleEmail}`"
                    class="kt-widget4__item overflow-hidden"
                >
                    <div class="kt-widget4__pic kt-widget4__pic--pic">
                        <UserAvatar :avatar-user="googleTeacher">
                            <div class="kt-badge kt-badge--lg kt-badge--primary">
                                {{ googleTeacher.lastName.substring(0,1) }}{{ googleTeacher.firstName.substring(0,1) }}
                            </div>
                        </UserAvatar>
                    </div>
                    <div class="kt-widget4__info">
                        <a
                            href="#"
                            class="kt-widget4__username text-nowrap text-truncate"
                            @click.stop.prevent="selectTeacher(googleTeacher)"
                        >
                            {{ googleTeacher.lastName }}, {{ googleTeacher.firstName }}
                        </a>
                        <p
                            class="kt-widget4__text"
                            v-html="$_utilMixins_format_email(googleTeacher.googleEmail) "
                        />
                    </div>
                </div>
            </div>
            <p
                v-if="googleTeachers.length == 0"
                class="kt-font-danger m-4"
            >
                A teacher must be added to create this courses.
            </p>
            <div
                v-if="!showAddTeacher && canEditCourse"
                class="w-100 my-4"
            >
                <a
                    href="#"
                    class="btn btn-clean btn-sm pull-right"
                    @click.stop.prevent="showAddTeacher = true"
                >
                    <i class="la la-user-plus" />
                    Change Teacher
                </a>
            </div>
        </div>

        <div
            v-if="showAddTeacher && canEditCourse"
            class="form-group pb-3 pt-2"
        >
            <div class="input-group ml-2">
                <select
                    v-model="teacher"
                    class="custom-select form-control"
                >
                    <option
                        :value="null"
                        selected
                    >
                        Set New Teacher
                    </option>
                    <option
                        v-for="(t) in teachers"
                        :key="`teach_${t.googleEmail}`"
                        :value="t"
                    >
                        {{ t.lastName }}, {{ t.firstName }} {{ t.googleEmail }}
                    </option>
                </select>
                <div class="input-group-append">
                    <template v-if="saving">
                        <span
                            class="input-group-text d-block"
                            style="min-width: 44px;"
                        >
                            <i
                                style="margin-top: 10px"
                                class="kt-spinner kt-spinner--sm kt-spinner--primary d-block"
                            />
                        </span>
                    </template>
                    <template v-else-if="success">
                        <span class="input-group-text">
                            <i class="la la-check kt-font-success" />
                        </span>
                    </template>
                    <template v-else-if="!saving">
                        <button
                            v-if="teacher"
                            class="btn btn-secondary kt-font-bold kt-font-primary"
                            @click.stop.prevent="add(teacher)"
                        >
                            Set
                        </button>
                        <span
                            v-else
                            class="input-group-text"
                        >
                            <i class="la la-plus" />
                        </span>
                    </template>
                </div>
            </div>
        </div>

        <div
            v-if="linkedSections.length > 1 && canEditCourse"
            class="form-group"
        >
            <label>Courses to link:</label>

            <div class="kt-checkbox-list mt-3">
                <label
                    v-for="cc in linkedSections"
                    :key="`linked_${cc.extCourseSectionId}`"
                    class="kt-checkbox kt-checkbox--solid"
                    :class="[cc.disabled ? 'kt-checkbox--brand ' : '']"
                    @click="canCheckCourse(cc, $event)"
                >
                    <input
                        v-model="cc.checked"
                        type="checkbox"
                    >
                    {{ cc.extCourseSectionId }}  {{ cc.name }}
                    <span />
                </label>
            </div>
        </div>
    </div>
    <div
        v-if="canEditCourse"
        class="kt-portlet__foot"
    >
        <div class="kt-form__actions kt-form__actions--right pull-right">
            <a
                href="#"
                class="btn btn-primary"
                :class="{
                    'kt-spinner kt-spinner--sm kt-spinner--light btn-success': saving,
                }"
                @click.stop.prevent="createCourse"
            >
                <i
                    v-if="!saving"
                    class="la la-rotate-right "
                />
                <span>
                    Create and Link
                </span>
            </a>
        </div>
    </div>
</div>

<div
    v-else
    class="kt-portlet "
>
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon pr-0">
                <SVGIcon
                    hex-color="#000"
                    class="kt-svg-icon mr-3"
                    name="googleClassroom"
                />
            </span>
            <h3 class="kt-portlet__head-title">
                <span>
                    Google Classroom
                </span>
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div
            class="alert alert-outline-primary"
            role="alert"
        >
            <div class="alert-icon">
                <i class="flaticon-warning" />
            </div>
            <div class="alert-text">
                This course is not linked to google classroom.
                Create a google classroom course below, or link to an existing course.
            </div>
        </div>

        <div
            v-if="canEditCourse"
            class="kt-section kt-section--last pt-3"
        >
            <a
                href="#"
                class="btn btn-sm btn-bold mr-4 btn-primary"
                :class="{
                    'kt-spinner kt-spinner--sm kt-spinner--light btn-success': creatingCourse,
                }"
                @click.stop.prevent="showCourseCreationPortlet = true"
            >
                <i
                    v-if="!creatingCourse"
                    class="la la-star-o"
                />
                <span>
                    Create Course
                </span>
            </a>

            <router-link
                :to="{ name: 'TeacherCourseGoogleLink', params: { extCourseSectionId: course.extCourseSectionId } }"
                class="btn btn-label-warning btn-sm"
            >
                Link Existing
            </router-link>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import Types from '../store/Types';
import courseMixins from '../store/mixins/courseMixins';
import utilMixins from '../store/mixins/utilMixins';
import UserAvatar from './UserAvatar.vue';

export default Vue.extend({
    name: 'CreateGoogleCourse',
    components: { UserAvatar },
    mixins: [
        courseMixins,
        utilMixins,
    ],
    data() {
        return {
            creatingCourse: false,
            teacher: null,
            saving: false,
            success: false,
            showAddTeacher: false,
            courseTitle: null,
            showCourseCreationPortlet: false,
            googleTeachers: [],
            linkedSections: [],
        };
    },
    computed: {
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        extCourseSectionId() {
            const { extCourseSectionId } = this.course;
            return extCourseSectionId || '';
        },
        googleDomain() {
            return this.$_userMixins_googleDomain;
        },
        teachers() {
            const { teachers } = this.$store.state.database;
            return teachers.filter((t) => Boolean(t.googleEmail));
        },
        canEditCourse() {
            return this.course.canEditCourse || false;
        },
    },
    watch: {
        course() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.creatingCourse = false;
            this.saving = false;
            this.success = false;
            this.courseTitle = null;
            this.teacher = null;
            this.showAddTeacher = false;
            this.googleTeachers = [];
            this.linkedSections = [];

            const { extCourseSectionId } = this;

            const linkedSections = [this.course];
            this.linkedSections = linkedSections.map((c) => {
                const course = { ...c }; // new pointers
                course.disabled = Boolean(course.extCourseSectionId == extCourseSectionId);
                course.checked = true;
                return course;
            }).sort((a, b) => {
                if (a.disabled > b.disabled) return -1;
                if (a.disabled < b.disabled) return 1;
                return 0;
            });

            const { schoolStaffId } = this.$store.state.user.school;
            const { teachers } = this.$store.state.database;

            const googleTeachers = this.course.teachers.map((t) => ({ ...t }));

            const myGoogleAccount = teachers.find((t) => t.schoolStaffId == schoolStaffId && t.googleEmail);
            if (myGoogleAccount) {
                const exists = googleTeachers.find((t) => t.googleEmail == myGoogleAccount.googleEmail);
                if (!exists) googleTeachers.push(myGoogleAccount);
            }
            if (googleTeachers.length > 0) {
                googleTeachers[0].isOwner = true;
            }
            const [googleTeacher] = googleTeachers;
            // force 1 teacher, since i cannot create a course on this step with multiple teachers
            this.googleTeachers = googleTeacher ? [googleTeacher] : [];
        },
        canCheckCourse(cc, event) {
            if (cc.disabled) event.preventDefault();
        },
        reset() {
            this.init();
            this.showCourseCreationPortlet = false;
        },
        add(teacher) {
            // force 1 teacher
            const t = { ...teacher };
            t.isOwner = true;
            this.googleTeachers = [t];
            this.teacher = null;
            this.ensureOwner();
            this.showAddTeacher = false;
        },
        remove(teacher) {
            const { schoolStaffId } = teacher;
            // remove teacher
            this.googleTeachers = this.googleTeachers.filter((t) => t.schoolStaffId != schoolStaffId);
            this.ensureOwner();
        },
        ensureOwner() {
            // ensure that an owner exists
            const owners = this.googleTeachers.filter((t) => t.isOwner);
            if (owners.length == 0) {
                const [firstTeacher] = this.googleTeachers;
                if (firstTeacher) firstTeacher.isOwner = true;
            }
        },
        selectTeacherAtIndex(idx) {
            const [teacher] = this.course.teachers;
            if (teacher) {
                this.$store.commit('openQuickPanelForTeacher', teacher);
            }
        },
        selectTeacher(teacher) {
            this.$store.commit('openQuickPanelForTeacher', teacher);
        },
        createCourse() {
            if (this.saving) return;

            // validate
            const { googleTeachers, showError, course } = this;
            const owners = googleTeachers.filter((t) => t.isOwner);
            const [owner] = owners;
            if (!owner) return showError('The course must have an owner');
            if (owners.length > 1) return showError('The course can only have one owner');
            if (googleTeachers.length == 0) return showError('Please add atleast one google teacher');

            const { dispatch } = this.$store;
            const { courseTitle, linkedSections, reset } = this;

            const v = this;
            v.saving = true;

            dispatch(Types.actions.CREATE_AND_LINK_COURSE_SECTION_TO_GOOGLE, {
                course: {
                    name: courseTitle,
                    owner,
                    primarySection: course,
                    teachers: googleTeachers,
                    linkedSections,
                },
                callback(err) {
                    v.saving = false;
                    if (err) {
                        console.error(err);
                        return showError('There was an error creating this course. Relink your google account and try again');
                    }
                    reset();
                    dispatch(Types.actions.REFRESH_GOOGLE_TABLES, (err2) => {
                        if (err2) {
                            console.error(err2);
                            return showError('There was an error refreshing this course.');
                        }
                    });
                },
            });
        },
    },
});
</script>

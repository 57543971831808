<template>
<div>
    <div
        v-if="course && course.canEditCourse"
        class="kt-portlet"
    >
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon pr-0">
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mr-3"
                        name="settings-2"
                    />
                </span>
                <h3 class="kt-portlet__head-title">
                    <span>
                        Settings
                    </span>
                </h3>
            </div>
            <div
                v-if="canDeleteCourse"
                class="kt-portlet__head-toolbar"
            >
                <div class="kt-portlet__head-actions">
                    <a
                        href="#"
                        class="btn btn-clean btn-clean-danger btn-sm btn-upper btn-bold kt-font-danger"
                        @click.stop.prevent="confirmDeleteCourse"
                    >
                        Delete Course
                    </a>
                </div>
            </div>
        </div>

        <div class="kt-portlet__body">
            <div class="kt-section">
                <div class="form-group row">
                    <div class="col-md-9">
                        <label>Course Title</label>
                        <input
                            v-model="courseSectionTitle"
                            type="text"
                            class="form-control"
                            maxlength="255"
                            placeholder=""
                            :disabled="!teacher || courseDeleted"
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label class="w-100">Color</label>
                        <div class="input-group">
                            <ColorSwatch
                                class="input-group-prepend"
                                :color-change="selectedCourseColor"
                                :color-selected="courseSectionColor || 'Blue'"
                                :item-index="0"
                                :disabled="!teacher || courseDeleted"
                            />
                            <input
                                type="text"
                                class="form-control"
                                disabled="disabled"
                                :value="courseSectionColor"
                            >
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label class=" w-100">Icon</label>
                        <div class="input-group kt-input-icon">
                            <EmojiPicker
                                :initial-emoji="courseSectionIcon"
                                :on-selected="emojiPicked"
                                class="input-group-prepend"
                                :disabled="!teacher || courseDeleted"
                            />
                            <input
                                type="text"
                                class="form-control pr-5"
                                readonly="readonly"
                                :value="courseSectionIcon"
                                :disabled="!teacher || courseDeleted"
                            >
                            <span
                                v-if="courseSectionIcon"
                                class="kt-input-icon__icon kt-input-icon__icon--right"
                                @click.stop.prevent="clearIcon"
                            >
                                <span><i class="la la-close" /></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Abbreviation</label>
                        <input
                            v-model="courseSectionAbbreviation"
                            type="text"
                            maxlength="2"
                            class="form-control col-9"
                            :disabled="!teacher || courseDeleted"
                        >
                    </div>
                    <div class="col-lg-6 ">
                        <label class="w-100 text-no-wrap">Hide From Nav</label>
                        <span class="kt-switch kt-switch--sm kt-switch--icon">
                            <label>
                                <input
                                    v-model="hideFromNav"
                                    type="checkbox"
                                    :disabled="!teacher || courseDeleted"
                                >
                                <span />
                            </label>
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="teacher && !courseDeleted"
                class="kt-section"
            >
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Course Name</th>
                                <th>Combine Course</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(cc, idx) in comboCourses"
                                :key="`course_${cc.extCourseSectionId}_${idx}`"
                            >
                                <td>
                                    <div class="text-left mt- ml-2">
                                        <router-link
                                            :to="{
                                                name: 'TeacherCourseInfo',
                                                params: {
                                                    extCourseSectionId: cc.extCourseSectionId,
                                                    schoolEmail: teacher.schoolEmail,
                                                }
                                            }"
                                        >
                                            {{ cc.name }}
                                        </router-link>
                                        <div
                                            class="text-muted"
                                        >
                                            {{ cc.extCourseSectionId }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="text-center mt-2">
                                        <span class="kt-switch kt-switch--sm kt-switch--icon">
                                            <label>
                                                <input
                                                    v-model="cc.isComboForCourse"
                                                    type="checkbox"
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="w-100 pb-3">
                <button
                    class="btn btn-primary btn-sm mt-2 pull-right"
                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                    :disabled="!teacher || courseDeleted"
                    @click.stop.prevent="save"
                >
                    <i
                        v-if="!saving"
                        class="fa fa-save"
                    />
                    {{ saving ? "Saving..." : "Save Settings" }}
                </button>
            </div>
        </div>
    </div>

    <ConfirmModal
        ref="confirmModal"
        :confirm-modal-visible="confirmVisible"
        :cancel-function="cancelConfirm"
        :confirm-function="confirmFunction"
        :processing="saving"
        :header="confirmHeader"
        :message="confirmMessage"
        :color="confirmColor"
    />
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import async from 'async';
import * as network from '../network';
import courseMixins from '../store/mixins/courseMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import SVGIcon from './SVGIcon/SVGIcon.vue';
import ColorSwatch from './ColorSwatch.vue';
import EmojiPicker from './EmojiPicker.vue';
import Types from '../store/Types';
import ConfirmModal from './ConfirmModal.vue';

export default Vue.extend({
    name: 'TeacherCourseSettings',
    components: {
        SVGIcon,
        ColorSwatch,
        EmojiPicker,
        ConfirmModal,
    },
    mixins: [
        courseMixins,
        teacherMixins,
        googleCourseMixins,
    ],
    props: {
        course: {
            type: Object,
            required: true,
        },
        teacher: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            courseSectionTitle: null,
            courseSectionColor: null,
            courseSectionIcon: null,
            courseSectionAbbreviation: null,
            hideFromNav: false,
            comboCourses: [],
            saving: false,
            confirmVisible: false,
            confirmHeader: '',
            confirmMessage: '',
            confirmColor: 'Red',
            confirmFunction: () => {},
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        courses() {
            const { course, teacher } = this;
            if (!course || !teacher) return [];
            const courses = this.$_courseMixins_getCoursesForStaffId(teacher.schoolStaffId);
            return courses.filter((c) => c.courseSectionId !== course.courseSectionId);
        },
        extCourseSectionId() {
            if (!this.course) return '';
            const { extCourseSectionId } = this.course;
            return extCourseSectionId || '';
        },
        canEditCourse() {
            if (!this.course) return false;
            return this.course.canEditCourse || false;
        },
        courseDeleted() {
            const { course } = this;
            if (!course) return false;
            return course.deleted;
        },
        canDeleteCourse() {
            const { user, courseDeleted } = this;
            const { school, userPermissions } = user;
            const { role } = school;

            if (courseDeleted) return false;
            return ['School Admin'].includes(role) || userPermissions.canManageCourses;
        },
    },
    mounted() {
        const { courseSectionColor, courseSectionTitle } = this.course;
        const { courseSectionIcon, courseSectionAbbreviation, hideFromNav } = this.course;
        const { courseSectionId, comboCourses } = this.course;
        const { schoolStaffId } = this.teacher;

        this.courseSectionTitle = courseSectionTitle || this.course.name;
        this.courseSectionColor = courseSectionColor;
        this.courseSectionIcon = courseSectionIcon;
        this.courseSectionAbbreviation = courseSectionAbbreviation;
        this.hideFromNav = hideFromNav;

        // mutable list of all possible combos, excluding this course
        const { courseSections } = this.$store.state.database;
        this.comboCourses = this.courses.map((c) => {
            const cc = { ...c };
            const isComboForCourse = comboCourses.find((combo) => combo.courseSectionId == c.courseSectionId);

            cc.comboCourseSectionId = cc.courseSectionId;
            cc.courseSectionId = courseSectionId;

            if (isComboForCourse) {
                cc.isComboForCourse = true;
                cc.hasOtherCombo = null;
            } else {
                cc.isComboForCourse = false;
                const otherCombo = courseSections.find((sect) => cc.comboCourseSectionId == sect.courseSectionId) || null;
                if (otherCombo) {
                    cc.hasOtherCombo = otherCombo.courseSectionCombos.find((m) => m.schoolStaffId == schoolStaffId && m.courseSectionId == cc.comboCourseSectionId);
                }
            }

            return cc;
        });
    },
    methods: {
        save() {
            const { showError, $store } = this;
            const { schoolId, schoolTermId } = $store.state.user.school;
            if (!this.course || !this.teacher || this.saving) return;
            if (!this.course.canEditCourse) return;
            this.saving = true;
            const { courseSectionId } = this.course;
            const { schoolStaffId } = this.teacher;

            const courseSectionTeacherMetadata = {
                schoolTermId,
                courseSectionId,
                schoolStaffId,
                courseSectionTitle: this.courseSectionTitle || null,
                courseSectionColor: this.courseSectionColor || null,
                courseSectionIcon: this.courseSectionIcon || null,
                courseSectionAbbreviation: this.courseSectionAbbreviation || null,
                hideFromNav: this.hideFromNav || false,
            };

            const courseSectionCombos = this.comboCourses
                .filter((cc) => cc.isComboForCourse)
                .map((cc) => ({
                    schoolTermId,
                    courseSectionId,
                    schoolStaffId,
                    comboCourseSectionId: cc.comboCourseSectionId,
                }));

            const v = this;
            async.auto({
                saveTeacherMetadata(next) {
                    const params = {
                        url: { schoolId, schoolTermId },
                        body: { courseSectionTeacherMetadata },
                    };
                    network.courseSectionsTeachers.upsertCourseSectionMetadata(params, next);
                },
                saveCourseCombos(next) {
                    const params = {
                        url: {
                            schoolId,
                            schoolTermId,
                            courseSectionId,
                            schoolStaffId,
                        },
                        body: { courseSectionCombos },
                    };
                    network.courseSectionsTeachers.upsertCourseSectionCombos(params, next);
                },
            }, 2, (err) => {
                if (err) {
                    v.saving = false;
                    return showError(err);
                }
                $store.dispatch(Types.actions.REFRESH_COURSE_SECTIONS, (err2) => {
                    v.saving = false;
                    if (err2) return showError(err2);
                });
            });
        },
        cancelConfirm() {
            this.confirmVisible = false;
        },
        confirmDeleteCourse() {
            const { course } = this;
            const { extCourseSectionId, name } = course;
            this.confirmHeader = `${extCourseSectionId} ${name}`;
            this.confirmMessage = 'Are you sure you want to delete this course?';
            this.confirmColor = 'Red';
            this.confirmFunction = this.deleteCourse;
            this.confirmVisible = true;
        },
        deleteCourse() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { course, user } = this;
            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { courseSectionId } = course;

            network.courseSections.deleteCourseSection({ url: { schoolId, schoolTermId, courseSectionId } }, (err) => {
                v.saving = false;
                v.confirmVisible = false;
                if (err) return v.showError('Unable to delete course');
                window.location.reload();
            });
        },
        selectedCourseColor(color) {
            this.courseSectionColor = color;
        },
        emojiPicked(emoji) {
            if (!emoji || !emoji.colons) {
                this.courseSectionIcon = null;
                return;
            }
            this.courseSectionIcon = emoji.native;
        },
        clearIcon() {
            this.courseSectionIcon = null;
        },
    },
});
</script>

<style scoped>
    .table td, .table th {
        vertical-align: center;
        text-align: center;
    }
</style>

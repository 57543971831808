<template>
<div class="row">
    <div class="form-group w-100 mx-2 mb-0">
        <label class="mb-2">Google Course</label>
        <p class="form-control-static py-2 mb-0 kt-font-bold kt-font-lg kt-font-dark">
            <span
                :class="{
                    'kt-badge--danger': googleCourse.googleCourseState == 'DELETED',
                    'kt-badge--warning': googleCourse.googleCourseState == 'ARCHIVED' || googleCourse.googleCourseState == 'PROVISIONED',
                    'kt-badge--success': googleCourse.googleCourseState == 'ACTIVE',
                }"
                class="kt-badge kt-badge--inline kt-badge--bold mr-3 mb-1"
            >
                {{ googleCourse.googleCourseState }}
            </span>
            <template v-if="googleCourse.alternateLink">
                <a
                    target="_blank"
                    :href="googleCourse.alternateLink"
                >
                    {{ googleCourse.googleCourseName }}
                </a>
            </template>
            <template v-else>
                <span>
                    {{ googleCourse.googleCourseName }}
                </span>
            </template>
        </p>
    </div>
    <div
        v-if="badCourseLink && canEditCourse"
        class="alert alert-warning my-4"
        role="alert"
    >
        <div class="alert-icon">
            <i class="flaticon-warning" />
        </div>
        <div class="alert-text">
            This google course must be ACTIVE in order to be used in SyncGrades.
            Remove this link and choose another course, or make the course ACTIVE then refresh data.
            <div
                v-if="isNotDeleted"
                class="w-100 d-flex justify-content-end"
            >
                <a
                    href="#"
                    class="btn btn-primary btn-sm"
                    @click.stop.prevent="activateCourse"
                >
                    <i class="la la-edit" />
                    Activate Course
                </a>
            </div>
        </div>
    </div>
    <div
        v-if="needsRefresh"
        class="alert alert-warning my-4"
        role="alert"
    >
        <div class="alert-icon">
            <i class="flaticon-warning" />
        </div>
        <div class="alert-text">
            This course is being provisioned in google. Wait a few moments and try to refresh google data again.
            Typically, google places courses in this locked state temporarily.
        </div>
    </div>

    <div
        v-else-if="notGoogleTeacher"
        class="alert alert-secondary my-4"
        role="alert"
    >
        <div class="alert-icon">
            <i class="flaticon-questions-circular-button" />
        </div>
        <div class="alert-text">
            <code>{{ googleEmail }}</code> is not a google teacher for this course, so the above google link may be
            inaccessible.
        </div>
    </div>
    <div class="w-100 mt-2 mb-4">
        <a
            href="#"
            class="btn btn-clean btn-clean-primary btn-sm"
            @click.stop.prevent="refresh"
        >
            <i class="la la-refresh" />
            Refresh Google Data
        </a>
        <a
            v-if="role == 'School Admin' || canEditCourse"
            href="#"
            class="btn btn-clean btn-clean-danger btn-sm"
            @click.stop.prevent="removeLink"
        >
            <i class="la la-remove" />
            Remove Link
        </a>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import Types from '../store/Types';
import courseMixins from '../store/mixins/courseMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import userMixins from '../store/mixins/userMixins';
import * as network from '../network';

export default Vue.extend({
    name: 'GoogleCourseStatus',
    mixins: [
        courseMixins,
        googleCourseMixins,
        teacherMixins,
        userMixins,
    ],
    computed: {
        courseSection() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        googleEmail() {
            return this.$_userMixins_unlinkedGoogleEmail;
        },
        role() {
            return this.$_userMixins_userRole;
        },
        googleTeachers() {
            const { googleCourse } = this;
            if (!googleCourse) return [];
            return googleCourse.googleTeachers || [];
        },
        notGoogleTeacher() {
            const { googleEmail } = this;
            const teacher = this.googleTeachers.find((t) => t.googleEmail == googleEmail);
            return !teacher;
        },
        badCourseLink() {
            const { googleCourse } = this;
            return (googleCourse.googleCourseState == 'ARCHIVED' || googleCourse.googleCourseState == 'DELETED');
        },
        isNotDeleted() {
            const { googleCourse } = this;
            return (googleCourse.googleCourseState != 'DELETED');
        },
        needsRefresh() {
            const { googleCourse } = this;
            return (googleCourse.googleCourseState == 'PROVISIONED');
        },
        canEditCourse() {
            return this.courseSection.canEditCourse || false;
        },
    },
    methods: {
        refresh() {
            const { showError } = this;
            this.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSE, (err) => {
                if (err) return showError(err);
            });
        },
        removeLink() {
            const { $store, showError } = this;
            const { schoolId, schoolTermId } = this.$_userMixins_school;
            const { courseSectionId } = this.courseSection;
            const params = { url: { schoolId, schoolTermId, courseSectionId } };
            network.courseSections.unlink(params, (err) => {
                if (err) return showError(err);
                $store.commit(Types.mutations.DELETE_GOOGLE_COURSE_COURSE_SECTIONS, courseSectionId);
            });
        },
        activateCourse() {
            const { $store, googleCourse, showError } = this;
            const { googleCourseId } = googleCourse;
            const { schoolId, schoolTermId } = this.$_userMixins_school;
            const googleCourseState = 'ACTIVE';
            network.google.modifyGoogleCourse({ url: { schoolTermId, schoolId, googleCourseId }, body: { googleCourseState } }, (err) => {
                if (err) return showError(err);
                $store.commit(Types.mutations.EDIT_GOOGLE_COURSE_STATE, { googleCourseId, courseState: googleCourseState });
            });
        },
    },
});
</script>

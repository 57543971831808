<template>
<div class="">
    <div class="form-group mb-3">
        <label>
            Course Teachers
        </label>
    </div>
    <div class="kt-widget4 mb-3">
        <div
            v-for="teacher in courseTeachers"
            :key="teacher.schoolEmail"
            class="kt-widget4__item"
        >
            <div class="kt-widget4__pic kt-widget4__pic--pic">
                <UserAvatar :avatar-user="teacher">
                    <div class="kt-badge kt-badge--lg kt-badge--success">
                        {{ teacher.lastName.substring(0,1) }}{{ teacher.firstName.substring(0,1) }}
                    </div>
                </UserAvatar>
            </div>
            <div class="kt-widget4__info">
                <router-link
                    class="kt-widget4__username"
                    :to="{name: 'TeacherInfo', params: {schoolEmail: teacher.schoolEmail}}"
                >
                    {{ teacher.lastName }}, {{ teacher.firstName }}
                </router-link>
                <p
                    class="kt-widget4__text"
                    v-html="$_utilMixins_format_email(teacher.schoolEmail) "
                />
            </div>
            <a
                v-if="courseTeachers.length > 0 && canEditCourse"
                href="#"
                class="btn btn-sm btn-icon-sm"
                @click.stop.prevent="removeTeacher(teacher)"
            >
                <i class="la la-close" />
            </a>
        </div>
    </div>
    <template v-if="canEditCourse">
        <div
            v-if="!showAddTeacher"
            class="w-100"
        >
            <a
                href="#"
                class="btn btn-clean btn-sm mt-2 pull-right"
                @click.stop.prevent="showAddTeacher = true"
            >
                <i class="la la-user-plus" />
                Add New Teacher
            </a>
        </div>
        <div
            v-else
            class="kt-section kt-section--first mb-2 w-100"
        >
            <div class="kt-section__body">
                <div class="form-group pb-3 pt-4">
                    <label>Add New Teacher:</label>
                    <div class="input-group">
                        <select
                            v-model="selectedSchoolStaffId"
                            class="custom-select form-control"
                        >
                            <option value="" />
                            <option
                                v-for="t in teachers"
                                :key="`${extCourseSectionId}_${t.schoolStaffId}`"
                                :value="t.schoolStaffId"
                            >
                                {{ t.lastName }}, {{ t.firstName }} - {{ t.schoolEmail }}
                            </option>
                        </select>

                        <div
                            class="input-group-append"
                        >
                            <template v-if="saving">
                                <span
                                    class="input-group-text d-block"
                                    style="min-width: 44px;"
                                >
                                    <i
                                        style="margin-top: 10px"
                                        class="kt-spinner kt-spinner--sm kt-spinner--primary d-block"
                                    />
                                </span>
                            </template>
                            <template v-else-if="success">
                                <span class="input-group-text">
                                    <i class="la la-check kt-font-success" />
                                </span>
                            </template>
                            <template v-else-if="!saving">
                                <button
                                    v-if="selectedSchoolStaffId"
                                    class="btn btn-secondary kt-font-bold kt-font-primary"
                                    @click.stop.prevent="save()"
                                >
                                    Add
                                </button>
                                <span
                                    v-else
                                    class="input-group-text"
                                >
                                    <i class="la la-plus" />
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import Types from '../store/Types';

import utilMixins from '../store/mixins/utilMixins';
import UserAvatar from './UserAvatar.vue';
import * as network from '../network';

export default Vue.extend({
    name: 'CourseSectionsTeachers',
    components: {
        UserAvatar,
    },
    mixins: [
        utilMixins,
    ],
    props: {
        course: {
            type: Object,
            required: true,
        },
        teacher: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
            showAddTeacher: false,
            selectedSchoolStaffId: 0,
            saving: false,
            success: false,
        };
    },
    computed: {
        ...mapState({
            externalDistrictId: (state) => state.user.school.externalDistrictId,
            deviceType: (state) => state.deviceType,
            user: (state) => state.user,
            courseSections: (state) => state.database.courseSections,
        }),
        teachers() {
            const { teachers } = this.$store.state.database;
            return teachers;
        },
        courseTeachers() {
            const { teachers } = this.course;
            return teachers || [];
        },
        courseSectionId() {
            const { courseSectionId } = this.course;
            return courseSectionId || 0;
        },
        extCourseSectionId() {
            const { extCourseSectionId } = this.course;
            return extCourseSectionId || '';
        },
        canEditCourse() {
            const { user, course } = this;
            const { userPermissions, school } = user;
            const { role } = school;
            const { teachers, canEditCourse } = course;

            if (canEditCourse || ['School Admin'].includes(role)) return true;

            if (!teachers.length) return userPermissions.canManageCourses;
            return teachers.length > 0 && Boolean(teachers.find((t) => t.schoolStaffId == user.school.schoolStaffId)) && userPermissions.canManageCourses;
        },
    },
    methods: {
        selectTeacher(teacher) {
            this.$store.commit('openQuickPanelForTeacher', teacher);
        },
        removeTeacher(teacher) {
            const v = this;
            if (v.saving || !teacher.schoolStaffId) return;
            v.saving = true;

            const { schoolStaffId } = teacher;
            const { user, course } = v;
            const { courseSectionId } = course;
            const { schoolTermId, schoolId } = user.school;

            const params = {
                url: {
                    schoolTermId, schoolId, courseSectionId,
                },
                body: { schoolStaffId },
            };
            network.courseSections.removeTeacher(params, (err) => {
                v.saving = false;
                v.showAddTeacher = false;
                if (err) {
                    v.showError(err);
                    return;
                }
                v.success = true;
                const record = {
                    courseSectionId,
                    schoolStaffId,
                };
                v.$store.commit(Types.mutations.REMOVE_COURSE_SECTION_TEACHER, record);
                setTimeout(() => {
                    v.success = false;
                }, 2500);
            });
        },
        save() {
            const v = this;
            const schoolStaffId = v.selectedSchoolStaffId;
            if (v.saving || !schoolStaffId) return;
            v.saving = true;

            const { user, course } = v;
            const { extCourseSectionId, courseSectionId, courseId } = course;
            const { schoolTermId, schoolId } = user.school;

            const secondaryTeacher = false;
            const params = {
                url: {
                    schoolTermId, schoolId, courseSectionId,
                },
                body: {
                    schoolStaffId,
                },
            };
            network.courseSections.addTeacher(params, (err) => {
                v.saving = false;
                v.showAddTeacher = false;
                if (err) {
                    v.showError(err);
                    return;
                }
                v.success = true;
                v.selectedSchoolStaffId = 0;
                const record = {
                    courseId,
                    courseSectionId,
                    schoolStaffId,
                    secondaryTeacher,
                };
                v.$store.commit(Types.mutations.ADD_COURSE_SECTION_TEACHER, record);
                const { courseTeachers } = v;
                if (courseTeachers.length == 1) {
                    const teacher = courseTeachers[0];
                    const { schoolEmail } = teacher;
                    v.$router.push({ name: 'TeacherCourseInfo', params: { extCourseSectionId, schoolEmail } });
                }
                setTimeout(() => {
                    v.success = false;
                }, 2500);
            });
        },
    },
});

</script>

<style scoped>
.truncate-text {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.link-container {
    height: 40vh;
    overflow-y: scroll;
}

i.la-close {
    color: #93a2dd !important;
}

</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.course && _vm.course.canEditCourse
        ? _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__head" }, [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _c(
                  "span",
                  { staticClass: "kt-portlet__head-icon pr-0" },
                  [
                    _c("SVGIcon", {
                      staticClass: "mr-3",
                      attrs: { "hex-color": "#0f88ef", name: "settings-2" },
                    }),
                  ],
                  1
                ),
                _vm._m(0),
              ]),
              _vm.canDeleteCourse
                ? _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                    _c("div", { staticClass: "kt-portlet__head-actions" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "btn btn-clean btn-clean-danger btn-sm btn-upper btn-bold kt-font-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.confirmDeleteCourse.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Delete Course ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "kt-section" }, [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-9" }, [
                    _c("label", [_vm._v("Course Title")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.courseSectionTitle,
                          expression: "courseSectionTitle",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        maxlength: "255",
                        placeholder: "",
                        disabled: !_vm.teacher || _vm.courseDeleted,
                      },
                      domProps: { value: _vm.courseSectionTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.courseSectionTitle = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("label", { staticClass: "w-100" }, [_vm._v("Color")]),
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("ColorSwatch", {
                          staticClass: "input-group-prepend",
                          attrs: {
                            "color-change": _vm.selectedCourseColor,
                            "color-selected": _vm.courseSectionColor || "Blue",
                            "item-index": 0,
                            disabled: !_vm.teacher || _vm.courseDeleted,
                          },
                        }),
                        _c("input", {
                          staticClass: "form-control",
                          attrs: { type: "text", disabled: "disabled" },
                          domProps: { value: _vm.courseSectionColor },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("label", { staticClass: "w-100" }, [_vm._v("Icon")]),
                    _c(
                      "div",
                      { staticClass: "input-group kt-input-icon" },
                      [
                        _c("EmojiPicker", {
                          staticClass: "input-group-prepend",
                          attrs: {
                            "initial-emoji": _vm.courseSectionIcon,
                            "on-selected": _vm.emojiPicked,
                            disabled: !_vm.teacher || _vm.courseDeleted,
                          },
                        }),
                        _c("input", {
                          staticClass: "form-control pr-5",
                          attrs: {
                            type: "text",
                            readonly: "readonly",
                            disabled: !_vm.teacher || _vm.courseDeleted,
                          },
                          domProps: { value: _vm.courseSectionIcon },
                        }),
                        _vm.courseSectionIcon
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "kt-input-icon__icon kt-input-icon__icon--right",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.clearIcon.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._m(1)]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("label", [_vm._v("Abbreviation")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.courseSectionAbbreviation,
                          expression: "courseSectionAbbreviation",
                        },
                      ],
                      staticClass: "form-control col-9",
                      attrs: {
                        type: "text",
                        maxlength: "2",
                        disabled: !_vm.teacher || _vm.courseDeleted,
                      },
                      domProps: { value: _vm.courseSectionAbbreviation },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.courseSectionAbbreviation = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("label", { staticClass: "w-100 text-no-wrap" }, [
                      _vm._v("Hide From Nav"),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "kt-switch kt-switch--sm kt-switch--icon",
                      },
                      [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.hideFromNav,
                                expression: "hideFromNav",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              disabled: !_vm.teacher || _vm.courseDeleted,
                            },
                            domProps: {
                              checked: Array.isArray(_vm.hideFromNav)
                                ? _vm._i(_vm.hideFromNav, null) > -1
                                : _vm.hideFromNav,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.hideFromNav,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.hideFromNav = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.hideFromNav = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.hideFromNav = $$c
                                }
                              },
                            },
                          }),
                          _c("span"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm.teacher && !_vm.courseDeleted
                ? _c("div", { staticClass: "kt-section" }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table table-bordered" }, [
                        _vm._m(2),
                        _c(
                          "tbody",
                          _vm._l(_vm.comboCourses, function (cc, idx) {
                            return _c(
                              "tr",
                              { key: `course_${cc.extCourseSectionId}_${idx}` },
                              [
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "text-left mt- ml-2" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "TeacherCourseInfo",
                                              params: {
                                                extCourseSectionId:
                                                  cc.extCourseSectionId,
                                                schoolEmail:
                                                  _vm.teacher.schoolEmail,
                                              },
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(cc.name) + " ")]
                                      ),
                                      _c("div", { staticClass: "text-muted" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(cc.extCourseSectionId) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "text-center mt-2" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-switch kt-switch--sm kt-switch--icon",
                                        },
                                        [
                                          _c("label", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: cc.isComboForCourse,
                                                  expression:
                                                    "cc.isComboForCourse",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  cc.isComboForCourse
                                                )
                                                  ? _vm._i(
                                                      cc.isComboForCourse,
                                                      null
                                                    ) > -1
                                                  : cc.isComboForCourse,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = cc.isComboForCourse,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          cc,
                                                          "isComboForCourse",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          cc,
                                                          "isComboForCourse",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      cc,
                                                      "isComboForCourse",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span"),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "w-100 pb-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm mt-2 pull-right",
                    class: {
                      "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                    },
                    attrs: { disabled: !_vm.teacher || _vm.courseDeleted },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.save.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.saving
                      ? _c("i", { staticClass: "fa fa-save" })
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm.saving ? "Saving..." : "Save Settings") +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("ConfirmModal", {
        ref: "confirmModal",
        attrs: {
          "confirm-modal-visible": _vm.confirmVisible,
          "cancel-function": _vm.cancelConfirm,
          "confirm-function": _vm.confirmFunction,
          processing: _vm.saving,
          header: _vm.confirmHeader,
          message: _vm.confirmMessage,
          color: _vm.confirmColor,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", { staticClass: "kt-portlet__head-title" }, [
      _c("span", [_vm._v(" Settings ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [_c("i", { staticClass: "la la-close" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Course Name")]),
        _c("th", [_vm._v("Combine Course")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
<div v-if="course.canEditCourse">
    <GoogleAccountWarning v-if="!googleIsConfigured" />
    <GoogleCourseInfo v-if="googleCourse" />
    <CreateGoogleCourse v-if="!googleCourse && googleIsConfigured" />
</div>
</template>

<script lang="ts">
import Vue from 'vue';

import userMixins from '../store/mixins/userMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import GoogleAccountWarning from './GoogleAccountWarning.vue';
import GoogleCourseInfo from './GoogleCourseInfo.vue';
import CreateGoogleCourse from './CreateGoogleCourse.vue';

export default Vue.extend({
    name: 'GoogleCoursePortlet',
    components: {
        GoogleCourseInfo,
        GoogleAccountWarning,
        CreateGoogleCourse,
    },
    mixins: [
        userMixins,
        googleCourseMixins,
    ],
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    computed: {
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        teachers() {
            const { teachers } = this.$store.state.database;
            return teachers.filter((t) => Boolean(t.googleEmail));
        },
        googleIsConfigured() {
            return this.$_userMixins_isGoogleConfigured;
        },
        googleDomain() {
            return this.$_userMixins_googleDomain;
        },
        isCourseLinkedToGoogle() {
            return Boolean(this.googleCourse);
        },
        extCourseSectionId() {
            const { extCourseSectionId } = this.course;
            return extCourseSectionId || '';
        },
    },
    methods: {

    },
});

</script>

<template>
<div :key="`key_${title}_${key}`">
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div
            v-if="canBeRestored"
            class="kt-portlet"
        >
            <div class="kt-portlet__body pb-1 mb-1">
                <div class="kt-section pb-0 mb-0">
                    <div class="kt-section__content">
                        <div class="alert alert-solid-danger alert-bold">
                            <div class="alert-icon">
                                <i class="flaticon-warning" />
                            </div>
                            <div class="alert-text">
                                This course has been deleted. Would you like to restore it?
                            </div>
                            <div class="alert-close">
                                <button
                                    type="button"
                                    class="btn btn-success btn-bold btn-upper btn-sm"
                                    @click.stop.prevent="restoreCourse"
                                >
                                    Restore Course
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="course.canEditCourse"
            class="row"
        >
            <div class="col-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__body  pb-0">
                        <div class="kt-section">
                            <div class="kt-section__content kt-section__content--fit">
                                <div class="kt-grid-nav kt-grid-nav--skin-light">
                                    <div class="kt-grid-nav__row row">
                                        <router-link
                                            v-for="item in items"
                                            :key="`course_nav_${item.icon}`"
                                            :to="item.route"

                                            class="kt-grid-nav__item col-sm-12 col-md-6 col-lg-4 col-xl-2"
                                            style="padding-top: 10px !important; padding-bottom: 10px !important; "
                                            @mouseover.native="item.hovered = true"
                                            @mouseleave.native="item.hovered = false"
                                        >
                                            <span class="kt-grid-nav__icon w-100">
                                                <SVGIcon
                                                    v-show="item.hovered"
                                                    class="kt-svg-icon-custom"
                                                    :name="item.icon"
                                                    :hex-color="'#0f88ef'"
                                                />
                                                <SVGIcon
                                                    v-show="!item.hovered"
                                                    class="kt-svg-icon-custom"
                                                    :name="item.icon"
                                                    :hex-color="'#c4cff9'"
                                                />
                                            </span>
                                            <span
                                                class="kt-grid-nav__title w-100"
                                            >
                                                {{ item.title }}
                                            </span>
                                            <!-- <span class="kt-grid-nav__desc">86%</span> -->
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div :class="$_userMixins_isGoogleDisabled || !course.canEditCourse ? 'col-12' : 'col-md-6'">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon pr-0">
                                <SVGIcon
                                    hex-color="#0f88ef"
                                    class="mr-3"
                                    name="info"
                                />
                            </span>
                            <h3 class="kt-portlet__head-title">
                                <span>
                                    Course Info
                                </span>
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            {{ extCourseSectionId }}
                        </div>
                    </div>
                    <div class="kt-portlet__body">
                        <div class="kt-widget kt-widget--user-profile-4 pb-5">
                            <div class="kt-widget__head">
                                <div class="kt-widget__content">
                                    <div class="kt-widget__section">
                                        <span
                                            v-if="course.courseSectionTitle"
                                            class="kt-widget__title pt-0"
                                        >
                                            {{ course.courseSectionTitle }}
                                        </span>
                                        <span
                                            v-if="course.courseSectionTitle"
                                            class="kt-widget__title pt-0"
                                        >
                                            {{ course.name }}
                                        </span>
                                        <span class="kt-widget__subtitle pt-2 pb-4">
                                            {{ course.extCourseSectionId }}
                                        </span>
                                    </div>
                                </div>
                                <div class="kt-widget__media">
                                    <CourseIcon
                                        v-if="course"
                                        :course="course"
                                        size="xl"
                                        class="kt-font-boldest"
                                    />
                                </div>
                                <div class="kt-widget__content">
                                    <div class="kt-widget__section">
                                        <span
                                            v-if="course.nextMeeting"
                                            class="kt-widget__subtitle pt-3"
                                        >
                                            {{ (course.nextMeeting.day || '').substring(0, 3) }},
                                            Period {{ course.nextMeeting.period }},
                                            {{ course.nextMeeting.room }}
                                        </span>
                                        <router-link
                                            v-if="teacher"
                                            :to="{ name: 'TeacherInfo', params: { schoolEmail: teacher.schoolEmail } }"
                                            class="kt-widget__subtitle pt-2"
                                        >
                                            {{ teacher.lastName }}, {{ teacher.firstName }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CourseSectionsTeachers :teacher="teacher" :course="course" />
                    </div>
                </div>
                <TeacherCourseSettings
                    v-if="$_userMixins_isGoogleDisabled"
                    :teacher="teacher"
                    :course="course"
                />
                <CourseSectionMeetings :course="course" />
            </div>
            <div
                v-if="!$_userMixins_isGoogleDisabled"
                class="col-md-6"
            >
                <TeacherCourseSettings
                    :teacher="teacher"
                    :course="course"
                />
                <GoogleCoursePortlet :course="course" />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import teacherMixins from '../store/mixins/teacherMixins';
import userMixins from '../store/mixins/userMixins';
import courseMixins from '../store/mixins/courseMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import CourseIcon from '../components/CourseIcon.vue';
import CourseSectionsTeachers from '../components/CourseSectionsTeachers.vue';
import CourseSectionMeetings from '../components/CourseSectionMeetings.vue';
import TeacherCourseSettings from '../components/TeacherCourseSettings.vue';
import GoogleCoursePortlet from '../components/GoogleCoursePortlet.vue';
import Types from '../store/Types';
import SVGIcon from '../components/SVGIcon/SVGIcon.vue';
import * as network from '../network';

export default Vue.extend({
    name: 'CourseInfo',
    components: {
        CourseIcon,
        GoogleCoursePortlet,
        CourseSectionsTeachers,
        CourseSectionMeetings,
        TeacherCourseSettings,
        SVGIcon,
    },
    mixins: [
        courseMixins,
        userMixins,
        teacherMixins,
        portfolioMixins,
    ],
    data() {
        return {
            key: 1,
            saving: false,
            items: [{
                route: { name: 'TeacherCourseRosters' },
                icon: 'students',
                hovered: false,
                title: 'Rosters',
            }, {
                route: { name: 'TeacherCourseAttendance' },
                icon: 'attendance',
                hovered: false,
                title: 'Attendance',
            }, {
                route: { name: 'TeacherCourseAssignments' },
                icon: 'assignments',
                hovered: false,
                title: 'Assignments',
            }, {
                route: { name: 'TeacherCourseGradebook' },
                icon: 'gradebook',
                hovered: false,
                title: 'Gradebook',
            }, {
                route: { name: 'TeacherCourseAverages' },
                icon: 'averages',
                hovered: false,
                title: 'Averages',
            }, {
                route: { name: 'TeacherCourseReports' },
                icon: 'progressReports',
                hovered: false,
                title: 'Reports',
            }],
            color: '#c4cff9',
            hoverColor: '#0f88ef',
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        isCourseLinkedToGoogle() {
            return Boolean(this.course.googleCourseId);
        },
        courseSections() {
            return this.$store.state.database.courseSections;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        schoolEmail() {
            return this.$route.params.schoolEmail;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        teacher() {
            const teacher = this.$_teacherMixins_getTeacherFromRoute();
            if (teacher) return teacher;
            if (this.teachers.length > 0) {
                return this.teachers[0];
            }
            return null;
        },
        teachers() {
            if (!this.course.teachers) return [];
            return this.course.teachers || [];
        },
        canBeRestored() {
            const { course } = this;
            if (!course) return false;
            if (!course.deleted) return false;

            const { user } = this;
            const { school, userPermissions } = user;
            const { role } = school;

            return ['School Admin'].includes(role) || userPermissions.canManageCourses;
        },
    },
    watch: {
        courseSections() {
            this.key += 1;
        },
        '$route.params.extCourseSectionId': function () {
            this.key += 1;
        },
        '$route.params.schoolEmail': function () {
            const { userName, $store } = this;
            const { schoolEmail } = this.$route.params;

            // remember the recent portfolio in the left nav menu
            if (schoolEmail != userName) {
                $store.commit(Types.mutations.ADD_PORTFOLIO_HISTORY, {
                    type: 'Teacher',
                    schoolEmail,
                });
            }
            this.key += 1;
        },
    },
    methods: {
        selectTeacher(teacher) {
            this.$store.commit('openQuickPanelForTeacher', teacher);
        },
        restoreCourse() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { course, user } = v;
            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { courseSectionId } = course;

            network.courseSections.restoreCourseSection({ url: { schoolId, schoolTermId, courseSectionId } }, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Unable to restore course');
                window.location.reload();
            });
        },
    },
});
</script>

<style scoped>
.kt-grid-nav__row {
    display: flex !important;
}
.kt-grid-nav__item {
    border: none !important;
    display: inline-block;
    padding: 2rem 0.75rem 3rem 0.75rem !important;
}

.kt-grid-nav__item:hover span.kt-grid-nav__title {
    color: #0f88ef !important;
}
.kt-grid-nav__item span.kt-grid-nav__title {
    color: #74788d !important;
}

.kt-grid-nav__icon svg {
    width: 32px;
    height: 32px;
}

</style>

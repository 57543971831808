var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-overlay",
    {
      staticClass: "w-100",
      attrs: {
        opacity: "0.7",
        variant: "transparent",
        blur: "0.5em",
        rounded: "sm",
        show: _vm.loading,
      },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("SVGIcon", {
                    staticClass: "kt-svg-icon kt-font-primary spinner",
                    staticStyle: { width: "120px", height: "auto" },
                    attrs: { name: "syncgrades" },
                  }),
                  _c(
                    "h6",
                    {
                      staticClass: "mt-4 kt-font-primary kt-font-xl p-4",
                      staticStyle: {
                        "background-color": "white",
                        "border-radius": "40px",
                      },
                    },
                    [_vm._v(" loading... ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "kt-portlet" }, [
        _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            _c(
              "span",
              { staticClass: "kt-portlet__head-icon pr-0" },
              [
                _c("SVGIcon", {
                  staticClass: "mr-3",
                  attrs: {
                    "hex-color": _vm.googleCourse ? "#0f88ef" : "#dc3545",
                    name: "google",
                  },
                }),
              ],
              1
            ),
            _c("h3", { staticClass: "kt-portlet__head-title" }, [
              _c("span", [_vm._v(" Google Classroom ")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "kt-form kt-form--label-right" }, [
          _c(
            "div",
            { staticClass: "kt-portlet__body" },
            [
              _c("GoogleCourseStatus"),
              !_vm.schoolGoogleAccountId
                ? _c("GoogleCourseCredentials")
                : _vm._e(),
              _c("GoogleCourseOwner"),
              _c("GoogleCourseTeachers"),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.course.canEditCourse
    ? _c(
        "div",
        [
          !_vm.googleIsConfigured ? _c("GoogleAccountWarning") : _vm._e(),
          _vm.googleCourse ? _c("GoogleCourseInfo") : _vm._e(),
          !_vm.googleCourse && _vm.googleIsConfigured
            ? _c("CreateGoogleCourse")
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.courseMeetings.length > 0
    ? _c("div", { staticClass: "kt-portlet" }, [
        _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            _c(
              "span",
              { staticClass: "kt-portlet__head-icon pr-0" },
              [
                _c("SVGIcon", {
                  staticClass: "mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "meetings" },
                }),
              ],
              1
            ),
            _vm._m(0),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet__body" }, [
          _c("div", { staticClass: "kt-section__content pt-3" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("th", [_vm._v("Period")]),
                      _vm._l(_vm.days, function (day, idx) {
                        return _c("th", { key: `${day}_${idx}` }, [
                          _c("div", { staticClass: "text-center" }, [
                            _vm._v(" " + _vm._s(day) + " "),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.tableData, function (row, rowIndex) {
                    return _c(
                      "tr",
                      { key: `row_${row.period}_${rowIndex}` },
                      [
                        _c("td", [
                          _c("div", { staticClass: "text-center mt-2" }, [
                            _c(
                              "span",
                              {
                                staticClass: "btn btn-md btn-icon",
                                class: [
                                  row.meetings.find((m) => m.course)
                                    ? row.color
                                    : "",
                                ],
                                staticStyle: { cursor: "default !important" },
                              },
                              [
                                row.meetings.find((m) => m.course)
                                  ? _c(
                                      "span",
                                      { style: { color: row.hexColor } },
                                      [_vm._v(" " + _vm._s(row.period) + " ")]
                                    )
                                  : _c("span", [
                                      _vm._v(" " + _vm._s(row.period) + " "),
                                    ]),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._l(row.meetings, function (meeting, meetingIdx) {
                          return _c(
                            "td",
                            {
                              key: `row_${row.period}_${rowIndex}_${meeting.day}_${meetingIdx}`,
                            },
                            [
                              meeting.course &&
                              meeting.course.teachers.length > 0
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "btn btn-clean",
                                      class: row.color,
                                      attrs: {
                                        to: {
                                          name: "TeacherCourseInfo",
                                          params: {
                                            schoolEmail:
                                              meeting.course.teachers[0]
                                                .schoolEmail,
                                            extCourseSectionId:
                                              meeting.course.extCourseSectionId,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(meeting.course.name) + " "
                                      ),
                                      _c("br"),
                                      _vm._v(" " + _vm._s(meeting.room) + " "),
                                    ]
                                  )
                                : meeting.course
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "btn btn-clean",
                                      class: row.color,
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(meeting.course.name) + " "
                                      ),
                                      _c("br"),
                                      _vm._v(" " + _vm._s(meeting.room) + " "),
                                    ]
                                  )
                                : _c("span", { staticClass: "text-muted" }, [
                                    _vm._v(" - "),
                                  ]),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", { staticClass: "kt-portlet__head-title" }, [
      _c("span", [_vm._v(" Course Meetings ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
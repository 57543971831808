<template>
<b-overlay
    opacity="0.7"
    variant="transparent"
    blur="0.5em"
    rounded="sm"
    class="w-100"
    :show="loading"
>
    <div class="kt-portlet">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon pr-0">
                    <SVGIcon
                        :hex-color="googleCourse ? '#0f88ef' : '#dc3545'"
                        class="mr-3"
                        name="google"
                    />
                </span>
                <h3 class="kt-portlet__head-title">
                    <span>
                        Google Classroom
                    </span>
                </h3>
            </div>
        </div>
        <div class="kt-form kt-form--label-right">
            <div class="kt-portlet__body">
                <GoogleCourseStatus />

                <GoogleCourseCredentials v-if="!schoolGoogleAccountId" />

                <GoogleCourseOwner />

                <GoogleCourseTeachers />
            </div>
        </div>
    </div>
    <template #overlay>
        <div class="text-center mt-5">
            <SVGIcon
                style="width: 120px; height: auto;"
                class="kt-svg-icon kt-font-primary spinner"
                name="syncgrades"
            />
            <h6
                class="mt-4 kt-font-primary kt-font-xl p-4"
                style="background-color: white; border-radius: 40px;"
            >
                loading...
            </h6>
        </div>
    </template>
</b-overlay>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Vue from 'vue';
import SVGIcon from './SVGIcon/SVGIcon.vue';
import GoogleCourseCredentials from './GoogleCourseCredentials.vue';
import GoogleCourseStatus from './GoogleCourseStatus.vue';
import GoogleCourseOwner from './GoogleCourseOwner.vue';
import GoogleCourseTeachers from './GoogleCourseTeachers.vue';
import googleCourseMixins from '../store/mixins/googleCourseMixins';

export default Vue.extend({
    name: 'GoogleCourseInfo',
    components: {
        GoogleCourseStatus,
        GoogleCourseCredentials,
        GoogleCourseOwner,
        GoogleCourseTeachers,
        SVGIcon,
    },
    mixins: [
        googleCourseMixins,
    ],
    computed: {
        ...mapState({
            school: (state) => state.user.school,
        }),
        schoolGoogleAccountId() {
            return this.school.googleAccountId || null;
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        loading() {
            const { cache } = this.$store.state.database;
            if (!this.googleCourse) return;
            const { googleCourseId } = this.googleCourse;
            const key = `googleCourseDataRefresh_${googleCourseId}`;
            const item = cache.find((i) => i.key == key && i.status == 'loading');
            return Boolean(item);
        },
    },
});
</script>

<style>
.spinner {
    transition-property: transform;
    transition-duration: 1s;
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>

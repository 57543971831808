var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showCourseCreationPortlet
    ? _c("div", { staticClass: "kt-portlet" }, [
        _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            _c(
              "span",
              { staticClass: "kt-portlet__head-icon pr-0" },
              [
                _c("SVGIcon", {
                  staticClass: "mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "googleClassroom" },
                }),
              ],
              1
            ),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-icon",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.reset.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "la la-close" })]
            ),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet__body" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Course Title:")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.courseTitle,
                  expression: "courseTitle",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: `${_vm.extCourseSectionId} ${_vm.course.name}`,
              },
              domProps: { value: _vm.courseTitle },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.courseTitle = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "d-block w-100" }, [
              _vm._v(" Google Teachers: "),
              _c("code", { staticClass: "pull-right" }, [
                _vm._v(" " + _vm._s(_vm.googleDomain) + " "),
              ]),
            ]),
            _vm.googleTeachers.length > 0
              ? _c(
                  "div",
                  { staticClass: "kt-widget4 pt-4" },
                  _vm._l(_vm.googleTeachers, function (googleTeacher) {
                    return _c(
                      "div",
                      {
                        key: `googleEmail_${googleTeacher.googleEmail}`,
                        staticClass: "kt-widget4__item overflow-hidden",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kt-widget4__pic kt-widget4__pic--pic",
                          },
                          [
                            _c(
                              "UserAvatar",
                              { attrs: { "avatar-user": googleTeacher } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-badge kt-badge--lg kt-badge--primary",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          googleTeacher.lastName.substring(0, 1)
                                        ) +
                                        _vm._s(
                                          googleTeacher.firstName.substring(
                                            0,
                                            1
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "kt-widget4__info" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "kt-widget4__username text-nowrap text-truncate",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.selectTeacher(googleTeacher)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(googleTeacher.lastName) +
                                  ", " +
                                  _vm._s(googleTeacher.firstName) +
                                  " "
                              ),
                            ]
                          ),
                          _c("p", {
                            staticClass: "kt-widget4__text",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$_utilMixins_format_email(
                                  googleTeacher.googleEmail
                                )
                              ),
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.googleTeachers.length == 0
              ? _c("p", { staticClass: "kt-font-danger m-4" }, [
                  _vm._v(" A teacher must be added to create this courses. "),
                ])
              : _vm._e(),
            !_vm.showAddTeacher && _vm.canEditCourse
              ? _c("div", { staticClass: "w-100 my-4" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-clean btn-sm pull-right",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.showAddTeacher = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "la la-user-plus" }),
                      _vm._v(" Change Teacher "),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.showAddTeacher && _vm.canEditCourse
            ? _c("div", { staticClass: "form-group pb-3 pt-2" }, [
                _c("div", { staticClass: "input-group ml-2" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.teacher,
                          expression: "teacher",
                        },
                      ],
                      staticClass: "custom-select form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.teacher = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        { attrs: { selected: "" }, domProps: { value: null } },
                        [_vm._v(" Set New Teacher ")]
                      ),
                      _vm._l(_vm.teachers, function (t) {
                        return _c(
                          "option",
                          {
                            key: `teach_${t.googleEmail}`,
                            domProps: { value: t },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(t.lastName) +
                                ", " +
                                _vm._s(t.firstName) +
                                " " +
                                _vm._s(t.googleEmail) +
                                " "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "input-group-append" },
                    [
                      _vm.saving
                        ? [_vm._m(1)]
                        : _vm.success
                        ? [_vm._m(2)]
                        : !_vm.saving
                        ? [
                            _vm.teacher
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-secondary kt-font-bold kt-font-primary",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.add(_vm.teacher)
                                      },
                                    },
                                  },
                                  [_vm._v(" Set ")]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [_c("i", { staticClass: "la la-plus" })]
                                ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.linkedSections.length > 1 && _vm.canEditCourse
            ? _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Courses to link:")]),
                _c(
                  "div",
                  { staticClass: "kt-checkbox-list mt-3" },
                  _vm._l(_vm.linkedSections, function (cc) {
                    return _c(
                      "label",
                      {
                        key: `linked_${cc.extCourseSectionId}`,
                        staticClass: "kt-checkbox kt-checkbox--solid",
                        class: [cc.disabled ? "kt-checkbox--brand " : ""],
                        on: {
                          click: function ($event) {
                            return _vm.canCheckCourse(cc, $event)
                          },
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: cc.checked,
                              expression: "cc.checked",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(cc.checked)
                              ? _vm._i(cc.checked, null) > -1
                              : cc.checked,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = cc.checked,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(cc, "checked", $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      cc,
                                      "checked",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(cc, "checked", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(cc.extCourseSectionId) +
                            " " +
                            _vm._s(cc.name) +
                            " "
                        ),
                        _c("span"),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]),
        _vm.canEditCourse
          ? _c("div", { staticClass: "kt-portlet__foot" }, [
              _c(
                "div",
                {
                  staticClass:
                    "kt-form__actions kt-form__actions--right pull-right",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      class: {
                        "kt-spinner kt-spinner--sm kt-spinner--light btn-success":
                          _vm.saving,
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.createCourse.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.saving
                        ? _c("i", { staticClass: "la la-rotate-right" })
                        : _vm._e(),
                      _c("span", [_vm._v(" Create and Link ")]),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ])
    : _c("div", { staticClass: "kt-portlet" }, [
        _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            _c(
              "span",
              { staticClass: "kt-portlet__head-icon pr-0" },
              [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon mr-3",
                  attrs: { "hex-color": "#000", name: "googleClassroom" },
                }),
              ],
              1
            ),
            _vm._m(3),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet__body" }, [
          _vm._m(4),
          _vm.canEditCourse
            ? _c(
                "div",
                { staticClass: "kt-section kt-section--last pt-3" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-bold mr-4 btn-primary",
                      class: {
                        "kt-spinner kt-spinner--sm kt-spinner--light btn-success":
                          _vm.creatingCourse,
                      },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.showCourseCreationPortlet = true
                        },
                      },
                    },
                    [
                      !_vm.creatingCourse
                        ? _c("i", { staticClass: "la la-star-o" })
                        : _vm._e(),
                      _c("span", [_vm._v(" Create Course ")]),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-label-warning btn-sm",
                      attrs: {
                        to: {
                          name: "TeacherCourseGoogleLink",
                          params: {
                            extCourseSectionId: _vm.course.extCourseSectionId,
                          },
                        },
                      },
                    },
                    [_vm._v(" Link Existing ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", { staticClass: "kt-portlet__head-title" }, [
      _c("span", [_vm._v(" Create Google Course ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticClass: "input-group-text d-block",
        staticStyle: { "min-width": "44px" },
      },
      [
        _c("i", {
          staticClass: "kt-spinner kt-spinner--sm kt-spinner--primary d-block",
          staticStyle: { "margin-top": "10px" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", { staticClass: "la la-check kt-font-success" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", { staticClass: "kt-portlet__head-title" }, [
      _c("span", [_vm._v(" Google Classroom ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "alert alert-outline-primary", attrs: { role: "alert" } },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "flaticon-warning" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " This course is not linked to google classroom. Create a google classroom course below, or link to an existing course. "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }